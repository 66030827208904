import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  List,
  ListItem,
  CTA,
} from "@/components/index"
import { color } from "@/theme/index"
import { useLocation } from "@reach/router"
import queryString from "query-string"

export default function Affiliate() {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Affiliate Program"
        description="Sonora Guitar Intensive Partners"
        noindex={true}
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={"9.6rem"}
        pb={["4.8rem", null, null, "0"]}
      >
        <Heading level="1" mt="5rem" mb="2rem">
          Affiliate Partners Program
        </Heading>
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["0", null, null, "2.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", 6 / 12]}
            textAlign="left"
            mb={["6rem", null, null, "0"]}
            px={["2.4rem", "0"]}
            mx="auto"
          >
            <Box
              width={["100%", null, 12 / 12, null]}
              p="2.4rem"
              textAlign="center"
              mb={"2.4rem"}
              bg={color.opal}
              borderRadius=".6rem"
              ml={["auto", null, 0, null]}
              mr={["auto", null, "1.8rem", null]}
            >
              <Box
                display="flex"
                mb="0.6rem"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text fontWeight="bold" color="amethyst">
                  Your Affiliate Link
                </Text>
              </Box>
              <Text level={2} fontSize={["15px", null, null, "17px"]}>
                {"https://learnwithsonora.com/?rid=" + urlParameters.query?.rid}
              </Text>
              {urlParameters.query?.rid && (
                <CTA
                  target="new"
                  to={
                    "https://www.facebook.com/sharer/sharer.php?u=https://www.learnwithsonora.com/?rid=" +
                    urlParameters.query?.rid
                  }
                  variant="primary"
                  mt="1.5rem"
                >
                  Share on Facebook
                </CTA>
              )}
            </Box>
            <Text mb="2.3rem">
              Earn money simply by promoting Sonora on your website, blog, email
              list, or social media channels.
            </Text>
            <Text mb="2.3rem" fontWeight="bold">
              Here’s how it works:
            </Text>
            <List bullets="checkmark">
              <ListItem>
                Send traffic to your custom affiliate invite link on this page.
              </ListItem>
              <ListItem>
                We track all visits via your link and attribute all enrollments
                made by those visitors within 60 days from the date of the first
                visit.
              </ListItem>
              <ListItem>
                On a quarterly basis, we pay you 10% of all qualified purchases
                via PayPal (no fees).
              </ListItem>
            </List>
            <Box>
              <Heading level="2" mt="5rem" mb="2rem">
                Affiliate FAQ
              </Heading>
              <Text fontWeight="bold" level="1">
                How do you track affiliate purchases?
              </Text>
              <Text level="2" mb="2.3rem">
                We use a 60-day cookie, which is linked to your Sonora affiliate
                ID. We track and pay all purchases made within 60 days of their
                initial visit. You must be the first to send the visitor to
                Sonora. If they do not make a purchase during the 60-day window,
                then they may be referred again by another affiliate.
              </Text>
              <Text fontWeight="bold" level="1">
                How can I see the performance of my campaign?
              </Text>
              <Text level="2" mb="2.3rem">
                We send you an automated monthly report from our CRM which
                tracks conversions attributed to your affiliate link.
              </Text>
              <Text fontWeight="bold" level="1">
                How much do Affiliates get paid?
              </Text>
              <Text level="2" mb="2.3rem">
                We pay a 10% affiliate fee on enrollments in both our core
                program ($1975) and our Fellowship Program ($4k-$5k)
              </Text>
              <Text fontWeight="bold" level="1">
                What is the applicant experience?
              </Text>
              <Text level="2" mb="2.3rem">
                When a visitor comes to our website from your affiliate link,
                they will be shown details about our programs. If they apply, they will be brought through a formal
                interview process to ensure they are at the appropriate level to
                succeed in the program and then they will be enrolled in the
                program.
              </Text>
            </Box>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
